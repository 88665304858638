<template>
  <div class='container'>
    <div class="head-bg"></div>
    <div class="content">
      <div class="pro-title">
          <h2 style="font-size: 3.4667vw;">{{ examInfo.title }}</h2>
          <div style="display: flex;align-items: center;justify-content: space-between;margin: 5.3333vw 0;">
            <div class="message">
              <p>{{progress.profileNum}}</p>
              <p>共布置</p>
            </div>
            <div class="message">
              <p>{{progress.testingNum}}</p>
              <p>已完成</p>
            </div>
            <div class="message">
              <p>{{progress.unTestingNum}}</p>
              <p>未完成</p>
            </div>
          </div>
          <div style="padding: 1.3333vw 2.6667vw;margin-right: 8vw;background: #FEF9F3;display: flex;align-items: center;border-radius: .8vw;">
            <img src="@/assets/project/time.png" alt="" style="width: 3.2vw;height: 3.2vw;margin-right: 2.1333vw;">
            <p style="color: #797979"> {{ examInfo.startTime }}  - {{ examInfo.endTime }}</p>
          </div>
      </div>
       <div class="box" v-for="(item,index) in projects" :key="item.id">
          <div class="box-title">
            <img :src="item.imgProjectLogo" alt="" style="width: 12.2667vw;height: 12.2667vw;margin-right: 2.6667vw;">
            <p>{{ item.name }}</p>
          </div>
          <div class="cake" :id="'cake'+item.id"></div>
       </div>
    </div>
    <div class="bottom">
      <button @click="$router.push({path:'classReport',query:{id:$route.query.id}})">班级报告</button>
      <button @click="$router.push('expertReport')">专家报告</button>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { resultStatistics,examSum } from '@/api/exam.js' 
export default {
  data(){
    return{
      chart:null,
      examInfo:{},
      progress:{},
      projects:{}
    }
  },
  created(){
    this.getDetail()
  },
  mounted(){
    this.getResultStatistics()
    // this.initOptions()
  },
  methods:{
    async getDetail(){
      const {id} = this.$route.query
      const {data} = await examSum({id})
      this.examInfo = data.exam
      this.progress=data.progress
    },
    async getResultStatistics() {
      const {id} = this.$route.query
      const {data} = await resultStatistics({examId:id})
      this.projects = data
      this.initCharts()
    },
    initCharts(){
      // console.log(39,echarts);
      this.projects.forEach((item,index)=>{
        this.initOptions(item)
        // const chart=echarts.init(document.getElementById(`cake${index}`))
        // this.chart.setOption(this.options)
      })
      /* this.chart=echarts.init(document.getElementById('main'))
      console.log(70,index,chart);
      this.chart.setOption(this.options) */
    },
    initOptions(obj){
      // console.log(78,obj);
      const data=obj.data.map(item=>{
        let temObj={}
        temObj.value=item.num
        temObj.name=item.name
        return temObj
      })
      // console.log('data',data);
      const options = {
         tooltip: {
           trigger: 'item'
         },
         legend: {
           orient: 'horizontal',
           bottom:'10%'
         },
         series: [
           {
             name: '人数',
             type: 'pie',
             radius: '50%',
             center: ['50%', '30%'],
             /* data: [
               { value: 1048, name: '良好' },
               { value: 735, name: '较差' },
             ], */
             data,
             emphasis: {
               itemStyle: {
                 shadowBlur: 10,
                 shadowOffsetX: 0,
                 shadowColor: 'rgba(0, 0, 0, 0.5)'
               }
             }
           }
         ]
       };
      //  延迟加载dom
       setTimeout(()=>{
        // console.log('options',options);
        // console.log('dom',document.getElementById('cake'+obj.id));
        const chart=echarts.init(document.getElementById('cake'+obj.id))
        // console.log('chart',chart);
        chart.setOption(options)
       },100)
      // this.initCharts()
    }
  }
}
</script>
<style lang='less' scoped>
.container{
  padding: 4vw 3.2vw 18.6667vw 3.2vw;
  font-size: 3.7333vw;
  min-height: 100%;
  background-color: #fff;
  .head-bg{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 52.2667vw;
    background: linear-gradient(180deg, #C4F3DF 0%, #FFFFFF 100%);
    z-index: 0;
  }
  .content{
    position: relative;
    .pro-title{
      display: flex;
      flex-direction: column;
      padding: 4vw 4.8vw;
      font-size: 3.2vw;
      border-radius: 2.6667vw;
      background-color: #fff;
      .message{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 30%;
        height: 14.9333vw;
        border-radius: 1.3333vw;
        background-color: #EFF9F8 ;
        // border: .2667vw solid rgba(187, 187, 187, 1);
        p{
          color: #10CA9B;
          font-size: 5.3333vw;
          &:last-child{
            color: #7AA7A0;
            font-size: 2.9333vw;
            // margin-top: .8vw;
          }
        }
      }
    }
    .box{
      margin-top: 4vw;
      padding: 3.2vw 3.2vw 6.4vw 3.2vw;
      width: 100%;
      border-radius: 2.6667vw;
      background-color: #fff;
      box-shadow: 0vw 0vw 2.6667vw 0vw rgba(187,187,187,0.15);
      .box-title{
        display: flex;
        align-items: center;
        font-weight: 550;
      }
      .cake{
        margin-top: 4vw;
        width: 90.9333vw;
        height: 55.4667vw;
      }
    }
  }
  .bottom{
    position: fixed;
    padding: 0 3.4667vw;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    width: 100%;
    height: 16vw;
    background-color: #fff;
    button{
      flex: 1;
      color: #fff;
      font-size: 4.8vw;
      text-align: center;
      height: 13.8667vw;
      background: #5EDBC9;
      border-radius: 13.3333vw;
      &:last-child{
        margin-left: 2.6667vw;
        background: #FBC746;
      }
    }
  }
}
</style>